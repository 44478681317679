/* Default Font Styles */
body {
  font-family: "Arial", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  font-weight: 17px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Georgia", serif;
  font-weight: bold;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

h1 {
  font-size: 2.5em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.75em;
}

h4 {
  font-size: 1.5em;
}

h5 {
  font-size: 1.25em;
}

h6 {
  font-size: 1em;
}

p,
ul,
ol,
dl,
blockquote {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
}

.pricing-title {
  color: green;
}

h1 {
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}

h1:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background-color: green;
}

/* loader */
.loader-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.loader-logo {
  width: 30%;
  height: auto;
}

/* navbar styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: green;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 15px 20px; /* Equal padding top and bottom */
}

.navbar-left,
.navbar-right {
  flex: 1;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.navbar-center {
  flex: 2;
  display: flex;
  justify-content: center; /* Center the navbar links */
  align-items: center;
}

.navbar-nav {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar-logo {
  max-width: 90px;
  height: auto;
  transition: max-width 0.3s ease;
}

@media (max-width: 768px) {
  .navbar-logo {
    max-width: 100px;
  }
}

@media (max-width: 480px) {
  .navbar-logo {
    max-width: 80px;
  }
}

.nav-item {
  margin: 0 10px;
  position: relative;
  height: fit-content;
}

.nav-item a {
  color: white;
  text-decoration: none;
  padding: 10px;
  transition: color 0.3s;
  font-weight: bold;
}

.nav-item a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background-color: white;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  transition: width 0.3s;
}

.nav-item a:hover::after,
.nav-item a.active::after {
  width: 100%;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  background-color: white;
  border-radius: 50%;
  color: green;
  font-size: 24px;
  font-weight: bold;
  padding: 5px;
}

.dropdown-button::before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border: 2px solid white;
  border-radius: 50%;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}

.dropdown-content a {
  color: green; /* Change the color of dropdown links to white */
  padding: 10px 20px;
  text-decoration: none;
  display: block;
}

.english {
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.dropdown-content a:hover {
  background-color: #e6e6e6;
}

.dropdown.show .dropdown-content {
  display: block;
}

.dropdown.show .dropdown-button {
  background-color: #e6e6e6;
}

.profile-icon img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.btn-primary {
  background-color: white;
  color: green;
  margin-right: 10px; /* Add small spacing between sign up and profile icon */
}

.btn-primary:hover {
  background-color: white;
}

.plus-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  color: green;
  font-size: 24px;
  font-weight: bold;
}

/* resposive */

.navbar-mobile {
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  background-color: green;
}
.menu {
  background: white;
  padding: 10px;
  border-radius: 5px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.responsive-nav {
  position: absolute;
  z-index: 10;
  right: 0px;
  top: 40px;
  background-color: green;
  padding: 20px;
  border-radius: 10px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.responsive-nav .navbar-center .navbar-nav .nav-item a {
  color: white;
}
.responsive-nav .dropdown-content a {
  color: green !important;
}
.responsive-nav .dropdown .english {
  color: white;
}

.responsive-nav .dropdown .dropdown-menu {
  list-style: none;
}

.responsive-nav .navbar-right .nav-item {
  background-color: green;
  color: white;
  width: max-content;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: none;
  }

  .navbar-mobile {
    display: flex;
  }

  .navbar-center {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .navbar-nav {
    flex-direction: column;
  }

  .nav-item {
    margin: 5px 0;
  }

  .dropdown-content {
    position: static;
    box-shadow: none;
    min-width: auto;
  }
}

.container {
  max-width: 400px;
}

select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* carosel on about us */

.carousel {
  position: relative;
  width: 100%;
  height: 300px;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 2rem;
  background-color: #f1f5f9;
}

.about-header {
  text-align: center;
  margin-bottom: 2rem;
}

.about-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #334155;
  margin-bottom: 0.5rem;
}

.about-subtitle {
  font-size: 1.25rem;
  color: #64748b;
}
.truncteDescription {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.about-content {
  max-width: 800px;
  width: 100%;
}

.about-us-description {
  text-align: justify;
}

.about-section {
  margin-bottom: 2rem;
}

.about-section-title {
  font-size: 1.75rem;
  font-weight: bold;
  color: #334155;
  margin-bottom: 1rem;
}

.about-section-text {
  font-size: 1.1rem;
  color: #475569;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .about-title {
    font-size: 2rem;
  }

  .about-subtitle {
    font-size: 1.1rem;
  }

  .about-section-title {
    font-size: 1.5rem;
  }

  .about-section-text {
    font-size: 1rem;
  }
}

/* create an account section */

.create-account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  padding: 1rem;
}

.create-account-title {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  font-weight: 600;
  color: green;
  text-align: center;
}

.create-account-form {
  width: 100%;
  max-width: 600px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* .form-group {
    margin-bottom: 1.5rem;
  } */
.form-group {
  margin-bottom: 1.5rem;
  width: 80%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.form-input {
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: green;
  transition: border-color 0.3s, background-color 0.3s;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
}

.form-input:focus {
  outline: none;
  border-color: green;
  background-color: #fff;
}

.password-input-wrapper {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 0.9rem;
  color: #777;
}

.password-toggle-icon.show {
  color: green;
}

.create-account-button {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  background-color: green;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.create-account-button:hover {
  background-color: green;
}

.login-link {
  margin-top: 1rem;
  font-size: 0.9rem;
  text-align: center;
}

.login-link a {
  color: green;
  text-decoration: none;
}

.login-link a:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .create-account-container {
    padding: 2rem 1rem;
  }

  .create-account-form {
    padding: 1.5rem;
  }

  .create-account-title {
    font-size: 1.8rem;
  }

  .form-input {
    font-size: 0.9rem;
  }

  .create-account-button {
    font-size: 0.9rem;
  }

  .login-link {
    font-size: 0.8rem;
  }
}

/* login section */

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f4f4;
  padding: 1rem;
}

.login-title {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  color: green;
}

.login-form {
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.login-p {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  color: #666;
}

.login-p a.create-account-link {
  color: green;
  text-decoration: none;
}

.login-p a.create-account-link:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .login-p {
    flex-direction: row;
    gap: 20px;
    font-size: 16px;
  }
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
}

.form-input {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f4f4f4;
  transition: border-color 0.3s, background-color 0.3s;
}

.form-input:focus {
  outline: none;
  border-color: green;
  background-color: #fff;
}

.password-input-wrapper {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 0.9rem;
  color: #777;
}

.password-toggle-icon.show {
  color: green;
}

.login-button {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  background-color: green;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: green;
}

.login-footer {
  margin-top: 1rem;
  font-size: 0.9rem;
  text-align: center;
}

.create-account-link {
  color: green;
  text-decoration: none;
}

.create-account-link:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .login-container {
    padding: 2rem 1rem;
  }

  .login-form {
    padding: 1.5rem;
  }

  .login-title {
    font-size: 1.8rem;
  }

  .form-input {
    font-size: 0.9rem;
  }

  .login-button {
    font-size: 0.9rem;
  }

  .login-footer {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .login-form {
    padding: 1.2rem;
  }

  .login-title {
    font-size: 1.6rem;
  }

  .form-group {
    margin-bottom: 1.2rem;
  }

  .form-label {
    font-size: 0.85rem;
  }

  .form-input {
    font-size: 0.85rem;
    padding: 0.6rem 0.8rem;
  }

  .password-toggle-icon {
    font-size: 0.8rem;
  }

  .login-button {
    font-size: 0.85rem;
    padding: 0.6rem 0.8rem;
  }

  .login-footer {
    font-size: 0.75rem;
  }
}

/* carosel section for searching jobs */

.carousel-section {
  height: 500px;
  position: relative;
  overflow: hidden;
}

.carousel-slide {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.carousel-slide.active {
  opacity: 1;
}

.carousel-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  max-width: 800px;
  padding: 2rem;
}

.carousel-content h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.carousel-content p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-input {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px 0 0 4px;
  flex-grow: 1;
}

.search-button {
  background-color: green;
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 0 4px 4px 0;
  text-decoration: none;
  cursor: pointer;
}

.search-button:hover {
  background-color: green;
}

/* PostJob.css */
.post-job {
  padding: 2rem;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.PostJobContainer {
  background-color: green;
}
.post-job h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group textarea {
  height: 150px;
  resize: vertical;
}

.btn-post {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-post:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .post-job {
    padding: 1.5rem;
  }

  .form-group input,
  .form-group textarea,
  .form-group select {
    font-size: 0.9rem;
  }

  .form-group textarea {
    height: 120px;
  }
}

/* About us section */

.about-us-page {
  padding: 100px;
}

.about-us-heading {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
  position: relative;
  color: green;
}

.about-us-container {
  display: flex;
  gap: 50px;
  justify-content: space-between;
  align-items: center;
}

.about-us-text {
  flex: 1;
  margin-right: 40px;
}

.about-us-subtitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 10px;
  color: green;
}

.about-us-subtitle::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 2px;
  background-color: red;
}

.about-us-description {
  font-size: 18px;
  line-height: 1.5;
  justify-content: end;
}

.about-us-image-container {
  flex: 1;
  position: relative;
}

.about-us-image {
  width: 70%;
  height: auto;
  border-top: 2px solid red;
  border-bottom: 2px solid red;
}

.about-us-image-container::before,
.about-us-image-container::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 4px;
}

.about-us-image-container::before {
  top: 0;
  left: 0;
}

.about-us-image-container::after {
  bottom: 0;
  right: 0;
}

@media (max-width: 768px) {
  .about-us-page {
    padding: 20px;
  }

  .about-us-heading {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .about-us-container {
    flex-direction: column;
  }

  .about-us-text {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .about-us-subtitle {
    font-size: 20px;
  }

  .about-us-description {
    font-size: 14px;
  }

  .about-us-image-container::before,
  .about-us-image-container::after {
    width: 30px;
    height: 3px;
  }
}

/* search section after navbar */

.hero-section {
  position: relative;
  height: 600px;
  background-image: url("../src/assets/caro1.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}

.hero-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  padding: 2rem;
  gap: 50px;
}

.left-container {
  flex: 1;
  color: white;
  text-align: left;
}

.left-container h1 {
  font-size: 2.7rem;
  margin-bottom: 1rem;
}

.left-container h2 {
  font-size: 1.7rem;
  margin-bottom: 1rem;
}

.left-container p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.right-container {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  border-radius: 8px;
  display: flex;

  flex-direction: column;
  align-items: center;
}

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.search-container input,
.search-container select,
.search-container button {
  margin: 0 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
}

.search-container button {
  background-color: green;
  color: white;
  cursor: pointer;
}

.right-container p {
  font-size: 0.9rem;
  color: #666;
}

@media (max-width: 768px) {
  .hero-section {
    height: fit-content;
  }
  .hero-content {
    flex-direction: column;
    padding: 1rem;
  }

  .left-container {
    text-align: center;
    margin-bottom: 2rem;
  }

  .left-container h1 {
    font-size: 2.5rem;
  }

  .left-container h2 {
    font-size: 1.8rem;
  }

  .left-container p {
    font-size: 1rem;
  }

  .search-container {
    flex-direction: column;
  }

  .search-container input,
  .search-container select,
  .search-container button {
    margin: 0.5rem 0;
    width: 100%;
  }
}

/* testimonial section */

.testimonial-section {
  padding: 4rem 2rem;
  background-color: #f5f5f5;
}
.testimony-header {
  color: green;
  text-align: center;
  font-size: 2rem;
}
.testimony-text {
  text-align: center;
}

.testimonial-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.testimonial-container::-webkit-scrollbar {
  display: none;
}

.testimonial-card {
  flex: 0 0 auto;
  width: 20%;
  margin-right: 2rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  scroll-snap-align: start;
}

.testimonial-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}

.testimonial-name {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: green;
}

.testimonial-position {
  font-size: 0.875rem;
  color: green;
  margin-bottom: 1rem;
}

.testimonial-text {
  font-size: 1rem;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .testimonial-section {
    padding: 3rem 1rem;
  }

  .testimonial-card {
    width: 90%;
    margin-right: 1.5rem;
    padding: 1.5rem;
  }
}

/* contact us section */

.contact-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.contact-form {
  background-color: #fff;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
}

.contact-form h2 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: green;
}

.contact-form .form-group {
  margin-bottom: 1.5rem;
}

.contact-form label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #333;
  display: block;
  margin-bottom: 0.5rem;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: inherit;
}

.contact-form textarea {
  resize: vertical;
  min-height: 150px;
}

.contact-form button {
  background-color: green;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: red;
}

@media (max-width: 768px) {
  .contact-section {
    padding: 2rem;
  }

  .contact-form {
    padding: 2rem;
  }

  .contact-form h2 {
    font-size: 1.75rem;
  }
}

/* AvailableSection.css */
.available-section {
  height: 500px;
  position: relative;
  overflow: hidden;
}

.available-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
}

.available-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.available-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.see-more-btn {
  background-color: green;
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.see-more-btn:hover {
  background-color: red;
}

@media (max-width: 768px) {
  .available-section {
    height: 400px;
  }

  .available-content {
    padding: 1.5rem;
  }

  .available-content h2 {
    font-size: 2rem;
  }

  .available-content p {
    font-size: 1rem;
  }

  .see-more-btn {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }
}

/* styling for the footer */

.footer {
  background-color: green;
  padding: 60px 0;
  font-size: 14px;
  color: white;
}
.footer-link {
  color: white;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-column {
  flex-basis: 22%;
  margin-bottom: 40px;
  text-align: justify;
  padding: 0px;
}

.footer-column h4 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;

  position: relative;
}
.footer-column h4:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 70px;
  height: 2px;
  background-color: #fff;
}

.footer-column h5 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-column p {
  line-height: 1.5;
}

.footer-link a {
  color: white;
  text-decoration: none;
}

.footer-links {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.contact-info .contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contact-info .contact-item svg {
  margin-right: 10px;
}

.footer-column ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li link {
  color: white;
  text-decoration: none;
  text-decoration: none;
}

.footer-column ul li link:hover {
  color: white;
}

.social-icons {
  display: flex;
  align-items: center;
}

.social-icons .social-icon {
  color: white;
  font-size: 20px;
  margin-right: 15px;
  text-decoration: none;
  align-items: center;
}

.social-icons .social-icon:hover {
  color: red;
}
.footer-link {
  color: white;
}

.footer-divider {
  height: 1px;
  background-color: #ddd;
  margin: 40px 0;
}

.footer-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.copyright {
  flex-basis: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.3rem;
  font-weight: bold;
}

.footer-links link {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}

.footer-links link:hover {
  color: white;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    flex-basis: auto;
    width: 30%;
    margin-bottom: 30px;
    text-align: center;
  }

  .social-icons {
    justify-content: center;
  }

  .footer-bottom {
    flex-direction: column;
  }

  .footer-column h4:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100px;
    height: 2px;
    background-color: #fff;
  }
}

/* styling for the partners section */

.partner-section {
  padding: 80px 0;
  background-color: #f5f5f5;
}

.partner-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.partner-content h1 {
  margin-bottom: 10px;
  color: green;
}

.partner-content .subheading {
  font-size: 18px;
  color: green;
  margin-bottom: 20px;
}

.partner-content .partner-text {
  font-size: 16px;
  color: #777;
  margin-bottom: 40px;
}

.partner-logos {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 40px;
}

.partner-logos img {
  max-width: 100%;
  height: auto;
  display: block;
}

@media (max-width: 768px) {
  .partner-logos {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .partner-logos {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .partner-content h2 {
    font-size: 28px;
  }

  .partner-content .subheading {
    font-size: 16px;
  }

  .partner-content .partner-text {
    font-size: 14px;
  }

  .partner-logos img {
    max-width: 120px;
    margin: 15px;
  }
}

@media (max-width: 480px) {
  .partner-content h2 {
    font-size: 24px;
  }

  .partner-content .subheading {
    font-size: 14px;
  }

  .partner-content .partner-text {
    font-size: 12px;
  }

  .partner-logos img {
    max-width: 100px;
    margin: 10px;
  }
}

/* card section */

.job-cards-section {
  padding: 3rem 1rem;
  background-color: #f5f5f5;
}

.job-cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  width: 100%;
}

.job-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.job-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: green;
}

.job-description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1.5rem;
}

.card-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-button {
  background-color: green;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.view-button:hover {
  background-color: red;
}

.share-button {
  background-color: transparent;
  border: none;
  color: red;
  font-size: 1.2rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.share-button:hover {
  color: red;
}

.see-more-link {
  display: block;
  text-align: center;
  margin-top: 2rem;
  color: red;
  text-decoration: none;
  transition: color 0.3s ease;
}

.see-more-link:hover {
  color: red;
}

.job-card {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  transition: transform 0.3s ease-in-out;
}

.job-card:hover {
  transform: scale(1.05);
}

.job-card {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.job-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .job-cards-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 480px) {
  .job-cards-container {
    grid-template-columns: 1fr;
  }
}

/* get app section */

.upcoming-app-awareness {
  background-color:#008000;
  padding: 4rem 2rem;
}

.app-awareness-container {
  max-width: 1200px;
  margin: 0 auto;
}

.app-awareness-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.app-awareness-header {
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  margin-bottom: 2rem;
}

.app-awareness-features {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 2rem;
}

.feature-item {
  flex-basis: 300px;
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.feature-item h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.feature-item p {
  font-size: 1rem;
  color: #666;
}

.app-download-section {
  color: white;
  font-size: 1.2rem;
  margin-top: 2rem;
}

.app-download-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.app-download-buttons img {
  max-width: 180px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .app-awareness-header {
    font-size: 2rem;
  }

  .feature-item {
    flex-basis: 100%;
  }

  .app-download-buttons img {
    max-width: 150px;
  }
}

/* .pricing-section */
.pricing-page {
  text-align: center;
  padding: 40px;
}

.pricing-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
}

.pricing-description {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
}

.pricing-plan {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 300px;
}

.pricing-plan-icon img {
  max-width: 100%;
  height: auto;
}

.pricing-plan-title {
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pricing-plan-features {
  list-style-type: none;
  padding: 0;
  text-align: left;
  margin-bottom: 30px;
}

.pricing-plan-features li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.pricing-plan-features li svg {
  color: red;
  margin-right: 10px;
}

.pricing-plan-price {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.pricing-plan-button {
  background-color: green;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.pricing-plan-button:hover {
  background-color: red;
}

.see-more-link {
  background-color: green;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;

  border: 2px solid green;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  font-size: 16px;
}
.see-more-link:hover {
  background-color: green;
  color: white;
}

/* IntroSection.css */

.SectionWrapper {
  position: relative;
  height: 400px;
  background-image: url("./assets/caro1.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.SectionWrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.ContentWrapper {
  position: relative;
  z-index: 1;
  text-align: center;
}

.Heading {
  font-size: 3rem;
  margin-bottom: 1rem;
  animation: headingAnimation 0.8s ease-in-out;
}

.Underline {
  width: 50px;
  height: 3px;
  background-color: green;
  margin: 0 auto;
  animation: underlineAnimation 0.8s ease-in-out;
}

@keyframes headingAnimation {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes underlineAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 50px;
  }
}

@media (max-width: 768px) {
  .SectionWrapper {
    height: 300px;
  }

  .Heading {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .SectionWrapper {
    height: 250px;
  }

  .Heading {
    font-size: 1.8rem;
  }
}

/* post job section */

.post-jobs-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 3rem 1rem;
  background-color: #f8f8f8;
}

@media (max-width: 768px) {
  .post-jobs-section {
    padding: 2rem 1rem;
  }
}

.left-section {
  flex-basis: 40%;
  background-color: #008000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

@media (max-width: 768px) {
  .left-section {
    flex-basis: 100%;
    padding: 1.5rem;
  }
}

.left-section-title {
  color: #fff;
  font-size: 2.5rem;
  text-align: center;
}

@media (max-width: 768px) {
  .left-section-title {
    font-size: 2rem;
  }
}

.right-section {
  flex-basis: 60%;
  padding: 2rem;
}

@media (max-width: 768px) {
  .right-section {
    flex-basis: 100%;
    padding: 1.5rem;
  }
}

.form-group {
  margin-bottom: 1.5rem;
}

.label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.job-type-radio {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.job-type-radio input[type="radio"] {
  margin-right: 0.5rem;
}

.textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  resize: vertical;
}

.salary-range {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.salary-range .input {
  flex-basis: 48%;
}

.salary-range span {
  margin: 0 0.5rem;
}

.submit-button {
  background-color: #008000;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #005d00;
}

/* 
request job section */

.LeftDiv {
  flex: 1;
  background-color: #008000;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  padding: 2rem;
}

.RequestJobsSection {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  padding: 4rem 1rem;
}

.RightDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.InputField {
  width: 100%;
  max-width: 300px;
  padding: 0.8rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.JobTypeSelect {
  width: 100%;
  max-width: 300px;
  padding: 0.8rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.RequestJobButton {
  background-color: #008000;
  color: white;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.RequestJobButton:hover {
  background-color: #006400;
}

@media (max-width: 768px) {
  .RequestJobsSection {
    flex-direction: column;
    padding: 2rem 1rem;
  }

  .LeftDiv {
    padding: 1.5rem;
    font-size: 1.8rem;
  }

  .RightDiv {
    padding: 1.5rem;
  }
}

/* profile section */

.profile-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding: 10px;
}

.profile-icon img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  filter: brightness(0) invert(1);
}

@media (max-width: 768px) {
  .profile-icon img {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 480px) {
  .profile-icon img {
    width: 25px;
    height: 25px;
    align-items: start;
  }
}

/* intro section styling */

.intro-section {
  position: relative;
  height: 400px;
  background-image: url("./assets/caro3.jpg");
  background-size: cover;
  background-position: center;
}

.intro-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.79);
  justify-content: center;
  align-items: center;
}

.intro-title {
  color: #fff;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  padding-top: 50px;
}

@media (max-width: 768px) {
  .intro-section {
    height: 300px;
  }

  .intro-title {
    font-size: 36px;
  }
}

@media (max-width: 480px) {
  .intro-section {
    height: 200px;
  }

  .intro-title {
    font-size: 24px;
  }
}

/* post job section */

.PageContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.Navbar {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 100;
}

.PostJobContainer {
  display: flex;
  flex-grow: 1;
  padding: 2rem;
}

.LeftContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 2rem;
}

.RightContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PostJobForm {
  width: 100%;
  max-width: 500px;
}

/* dashboard section */

.dashboard-container {
  padding: 2rem;
}

.dashboard-title {
  text-align: center;
  margin-bottom: 2rem;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2rem;
}

.dashboard-card {
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  border-radius: 8px;
}

.dashboard-card-title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.dashboard-card-content {
  font-size: 1rem;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .dashboard-container {
    padding: 1.5rem;
  }

  .dashboard-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (max-width: 480px) {
  .dashboard-container {
    padding: 1rem;
  }

  .dashboard-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

/* reset password section */

.reset-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
  background-color: #f5f5f5;
  padding: 2rem;
}

.reset-password-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-width: 400px;
  width: 100%;
}

.reset-password-card h2 {
  text-align: center;
  margin-bottom: 1rem;
  color: green;
}

.reset-password-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reset-password-form input {
  padding: 0.75rem 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.reset-password-form button,
.verifyemail {
  background-color: green;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reset-password-form button:hover {
  background-color: red;
}

.verifyemail:hover {
  background-color: green;
}

@media (max-width: 768px) {
  .reset-password-container {
    padding: 1rem;
  }

  .reset-password-card {
    padding: 1.5rem;
  }

  .reset-password-form input,
  .reset-password-form button {
    font-size: 0.875rem;
  }
}

/* our team section */

.our-team-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background-color: #007c42;
  z-index: -1;
}

.our-team-section {
  padding: 2rem 1rem;
  background-color: #f5f5f5;
}

.section-title {
  text-align: center;
  margin-bottom: 2rem;
  color: #007c42;
}

.section-subtitle {
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem; /* Reduced the grid gap */
  justify-content: center;
}

.team-card {
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  text-align: center;
  padding: 1.2rem;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  width: 80%; /* Reduced the card width */
  margin: 0 auto;
}

.team-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.team-member-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 0.8rem;
}

.team-member-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-member-info {
  text-align: center;
}

.team-member-name {
  font-size: 1rem;
  font-weight: bold;
  color: #007c42;
  margin-bottom: 0.4rem;
}

.team-member-position {
  font-size: 0.8rem;
  font-weight: bold;
  color: #555;
  margin-bottom: 0.8rem;
}

.team-member-description {
  font-size: 0.75rem;
  color: #777;
  margin-bottom: 0.8rem;
  text-align: center;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 0.8rem;
}

.social-icons a {
  color: #007c42;
  margin: 0 0.3rem;
  font-size: 1rem;
  transition: color 0.3s ease-in-out;
}

.social-icons a:hover {
  color: red;
}

@media (max-width: 768px) {
  .team-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0.8rem;
  }

  .team-card {
    width: 95%;
  }
}

@media (max-width: 480px) {
  .team-grid {
    grid-template-columns: 1fr;
    grid-gap: 0.6rem;
  }

  .team-card {
    padding: 0.8rem;
    width: 100%;
  }

  .team-member-image {
    width: 80px;
    height: 80px;
    margin: 0 auto 0.6rem;
  }
}

/* my profile design section*/

.profile-container {
  width: 100%;
  max-width: 48rem;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
}

.profile-header {
  padding: 1.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.names {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}
.profile-header h2 {
  color: green;
  text-align: center;
  font-weight: bold;
}
.profile-header p {
  text-align: center;
}

.profile-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a202c;
}

.profile-description {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #4a5568;
}

.profile-content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}

.form-group__title {
  font-size: 1rem;
  font-weight: 600;
  color: green;
}

.form-label {
  font-size: 0.875rem;
  color: green;
}

.form-input {
  padding: 0.5rem 0.75rem;
  border: 1px solid #d2d6dc;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  color: #1a202c;
}

.form-textarea {
  padding: 0.5rem 0.75rem;
  border: 1px solid #d2d6dc;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  color: #1a202c;
  resize: vertical;
}

.form-button {
  padding: 0.5rem 1rem;
  border: 1px solid #d2d6dc;
  border-radius: 0.375rem;
  background-color: #f0f0f0;
  font-size: 0.875rem;
  color: #4a5568;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.form-button:hover {
  background-color: #e5e7eb;
  color: #1a202c;
}

.skill-badges {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.skill-badge {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: #f0f0f0;
  font-size: 0.875rem;
  color: #1a202c;
}

.skill-badge__remove {
  margin-left: 0.5rem;
  color: #718096;
  transition: color 0.2s;
  cursor: pointer;
}

.skill-badge__remove:hover {
  color: #4a5568;
}

.skill-input {
  display: flex;
  gap: 0.5rem;
}

.experience-card {
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
}

.experience-card__content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 10px 0px;
}

.add-experience-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: green;
  color: white;
  margin-top: 20px;
}

.plus_button {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.profile-footer {
  padding: 1rem 1.5rem;
  border-top: 1px solid #e5e7eb;
}

.save-button {
  width: 100%;
  background-color: green;
  color: white;
}

/* post job section */

.job-posting-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 1rem;
}

.job-posting-title {
  text-align: center;
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 3rem;
}

.job-posting-form-preview {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.job-posting-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-label {
  font-weight: 500;
  color: green;
}

.form-input,
.form-select,
.form-textarea {
  padding: 0.75rem 1rem;
  border: 1px solid #d2d6dc;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  color: #1a202c;
}

.form-textarea {
  height: 8rem;
  resize: vertical;
}

.form-button {
  padding: 0.75rem 1rem;
  background-color: green;
  border: none;
  border-radius: 0.375rem;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.job-posting-preview {
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  padding: 1.5rem;
}

.job-posting-preview-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: green;
}

.job-posting-preview-info {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
}

.job-posting-preview-info-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4a5568;
}

.job-posting-preview-icon {
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
}

.job-posting-preview-description-title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: green;
}

.job-posting-preview-description-text {
  color: #4a5568;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .job-posting-form-preview {
    display: flex;
    flex-direction: column;
  }
}

.premium{
  height: 750px;
  width: auto;
  background-color: green;
  padding-top: 30px;
  padding-left: 100px;
  padding-right: 100px;
  color: white;
  overflow-x:hidden;
  
  }
  
  
  .find{
    background-color: white;
    text-align: center;
    padding-top: 20px;
    margin-top: 50px;
    padding-bottom: 20px;
    color: #006400;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
    display: block;
    border-radius: 15px;
  }


  .find1{

    text-align: center;
    padding-top: 20px;
    margin-top: 50px;
    padding-bottom: 20px;
    color: white;
    padding-left: 60px;
    padding-right: 60px;
    border-radius: 15px;
    margin-bottom: 50px;
  }

  .wacom{
margin-top: 50px;

  }


